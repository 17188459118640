//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "../../../";
import router from '@/router'


class CategoriaServices {
  
  listarCategoria(params) {  
    return http.get("/inventario/maestro/listarCategoria", { params })
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }  

   
  listarCategoriaFiscal(params) {  
    return http.get("/inventario/maestro/listarCategoriaFiscal", { params })
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }  
 
  categoriaActualizar(data) {
    return http.post("/inventario/maestro/categoriaActualizar", data)
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
  
  categoriaFiscalActualizar(data) {
    return http.post("/inventario/maestro/categoriaFiscalActualizar", data)
      .catch((error) => {
        if (error.response.status == 403) {
          store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
          router.push('/pages/login').catch(error => {
            if (error.name != 'NavigationDuplicated') {
              throw error
            }
          });
        }
      });
  }
   
}

export default new CategoriaServices();