<template>
  <div>
    <v-autocomplete
      :prepend-icon="icon ? icons.mdiAlphaCCircle : null"
      :clearable="clearable"
      hide-details="auto"
      :disabled="disabled"
      v-model="select"
      :items="lista"
      label="Sub Categoria"
      item-value="id"
      item-text="nombre"
      :dense="outlined"
      :outlined="outlined"
      :loading="cargando"
      :append-outer-icon="add ? icons.mdiPlusCircleOutline : null"
      @click:append-outer="abrirModal()"
      :rules="rules"
    >
    </v-autocomplete>
    <v-dialog v-model="dialog" width="700">
      <Actualizar @GetActualizarLista="GetActualizarLista" :padre="padre"></Actualizar>
    </v-dialog>
  </div>
</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { mdiAlphaCCircle, mdiPlusCircleOutline } from '@mdi/js'  
import SubCategoriaServices from '@/api/servicios/inventario/maestro/SubCategoriaServices'
import Actualizar from './Actualizar.vue'
export default {
  
  components: { 
    Actualizar
  },
  props: {
    value: String,
    jsonRespueta: Boolean,
    disabled: Boolean,
    dense: Boolean,
    outlined: Boolean,
    icon: Boolean,
    todos: Boolean,
    default: Number,
    clearable: Boolean,
    padre: {
      type: Number,
      default: -1,
    },
    rules: Array,
    add: {
      type: Boolean,
      default: true,
    },
    filtros:Object
  },
  watch:{
    padre : function(){
        
        this.cargarDatos()
       
    },
    filtros: function (_new, _old) {
      if (JSON.stringify(_new) != JSON.stringify(_old)) {
        this.cargarDatos()
      }
    },
  },
  setup(props, contex) {
    const lista = ref([])
    const select = ref(null)
    const cargando = ref(false)
    const dialog = ref(false)

    watch(props, () => {
      select.value = props.value
    })
    onBeforeMount(() => {
      cargarDatos()
      if (props.value != undefined) {
        select.value = props.value
      }
    })

    const GetActualizarLista = item => {
      lista.value.push(item)
      select.value = item.id
      dialog.value = false
    }
    const abrirModal = () => {
      dialog.value = true
    }
    const cargarDatos = () => {
      lista.value = []
      cargando.value = true

      var _filtro = { id_categoria: props.padre }
      if (props.filtros) {
        _filtro = { ..._filtro, ...props.filtros }
      }
      
      try {
        SubCategoriaServices.listarSubCategoria(_filtro)
          .then(response => {
            console.log(response)
            if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
              lista.value = response.data.datos
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            cargando.value = false
          })
      } catch (error) {
        cargando.value = false
      }
    }
 
    watch(select, () => {
      const valor = lista.value.find(item => item.id == select.value)

      if (props.jsonRespueta == true) {
        contex.emit('input', valor == undefined ? null : valor)
      } else contex.emit('input', select.value)
    })

    return {
      icons: {
        mdiAlphaCCircle,
        mdiPlusCircleOutline,
      },
      lista,
      select,
      cargando,
      dialog,
      abrirModal,
      GetActualizarLista,
      cargarDatos
    }
  },
}
</script>